import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "newRow", "toggleButton", "editIcon", "cancelIcon" ]

  initialize() {
    this.newUrl = this.element.dataset.postParamsNewUrlValue
  }

  connect() {
  }

  new() {
    fetch(this.newUrl)
      .then(response => response.text())
      .then(html => {
        this.updateForm(html)
        console.log(html)
      })
  }

  delete() {
    let form = this.element.querySelector(`[data-post-param-form-id~=new_post_param]`)
    form.remove();
  }

  updateForm(html, postParamsId) {
    let postParamRow = this.element.querySelector(`[data-post-param-id~="post_param_${postParamsId}"]`);
    !!postParamRow ? postParamRow.outerHTML = html : this.newRowTarget.insertAdjacentHTML('beforebegin', html);
  }

  toggleEdit(event) {
    event.preventDefault()
    let postParamId = event.target.closest('[data-post-param-id]').dataset.postParamId
    let form = this.element.querySelector(`[data-post-param-form-id~="${postParamId}"]`)
    form.classList.toggle('hidden')
    this.updateToggleText(form, postParamId)

    event.target.closest('button').classList.toggle('btntwo-green')
    event.target.closest('button').classList.toggle('btntwo-grey')
  }

  updateToggleText(form, postParamId) {
    let paramBtn = this.element.querySelector(`#${postParamId}_btn`);
    if (form.classList.contains('hidden')) {
      paramBtn.innerHTML = 'Edit'
    } else {
      paramBtn.innerHTML = 'Cancel'
    }
  }

  refresh() {
    fetch(this.indexUrl)
      .then(response => response.text())
      .then(html => this.refreshContainer(html))
  }

  refreshContainer(html) {
    this.element.outerHTML = html
  }

  appendNew(event) {
    let [data, status, xhr] = event.detail
    let postParamsId = data.querySelector("form").dataset.postParamsId;
    toastr.success("Post Param saved successfully.");
    this.updateForm(xhr.response, postParamsId)
    event.target.closest('tr').remove()
  }

  onDelete(event) {
    let [data, status, xhr] = event.detail
    toastr.success("Post Param successfully deleted.")
    event.target.closest('tr').remove()
  }
 }
