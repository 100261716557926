import { Controller } from "stimulus"

export default class extends Controller {
  submit() {
    Rails.fire(this.element, 'submit')
  }

  onSuccess(event) {
    let [data, status, xhr] = event.detail
    toastr.success("Saved!")
  }
}
