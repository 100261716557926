import { Controller } from "stimulus"

const questionType = {
  text: "Text",
  text_area: "Multi line text",
  number: "Number",
  url: "Website",
  tel: "Phone",
  email: "Email",
  date_selector: "Date Selector",
  date_field: "Date typeahead",
  select: "Dropdown",
  ssn: "Encrypted SSN"
}

export default class extends Controller {
  static targets = [ "input", "value", "option", "optionsGrid", "carret" ]

  initialize() {
  }

  showOptions(event) {

    event.preventDefault()
    this.carretTarget.classList.toggle('input--toggable__active')
    this.optionsGridTarget.classList.toggle("hidden")
  }

  selectType(event) {
    event.preventDefault()
    let type = event.currentTarget.dataset.questionType
    let value = event.currentTarget.dataset.questionValue
    this.inputTarget.setAttribute('value', type)
    this.valueTarget.setAttribute('value', value)
    this.optionTargets.forEach((item) => { item.classList.remove('active') })
    event.currentTarget.classList.add('active')
  }
}
