import ChartController from "./chart_controller"

export default class extends ChartController {
  createGraph(data) {
    if (this.isDataSetEmpty(data)) {
      this.showEmpty()
      return
    }
    if (data.stats.length > 0) {
      this.hideEmpty()
      this.showLoader()
      this.graphTarget.innerHTML = ""
      this.graphTarget.classList.remove('hidden')

      Morris.Line({
        element: this.graphTarget,
        data: data.stats,
        xkey: 'date',
        ykeys: ['total_count', 'completed_count'],
        labels: ['Total visits', 'Leads generated'],
        lineColors: ["#828EA8", "#4BD1BB"],
        pointSize: 0,
        continuousLine: true
      })
    }
  }

  isDataSetEmpty(data) {
    return this.isCompletedCountDataSetEmpty(data) && this.isTotalCountDataSetEmpty(data)
  }

  isCompletedCountDataSetEmpty(data) {
    let completedSet = new Set(this.completedCount(data))
    if (completedSet.size < 1 || (completedSet.size == 1 && completedSet.has(0))) {
      return true
    }

    return false
  }

  isTotalCountDataSetEmpty(data) {
    let totalSet = new Set(this.totalCount(data))
    if (totalSet.size < 1 || (totalSet.size == 1 && totalSet.has(0))) {
      return true
    }

    return false
  }

  completedCount(data) {
    return data.stats.map(stat => stat.completed_count)
  }

  totalCount(data) {
    return data.stats.map(stat => stat.total_count)
  }
}
