import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "container", "hideable" ]

  initialize() {
    super.initialize()
  }

  connect() {
    this.check()
  }

  check(event) {
    let innerWidth = !!event ? event.currentTarget.innerWidth : window.innerWidth

    if (innerWidth < 992) {
      this.showScreenSizeError()
    } else {
      this.removeScreenSizeError()
    }
  }

  showScreenSizeError() {
    this.containerTarget.classList.remove('hidden')
    this.hideableTargets.forEach(hideableTarget => {
      hideableTarget.classList.add('hidden')
    })
  }

  removeScreenSizeError() {
    this.containerTarget.classList.add('hidden')
    this.hideableTargets.forEach(hideableTarget => {
      hideableTarget.classList.remove('hidden')
    })
  }
}
