import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "result" ]

  submit() {
    Rails.fire(this.element, 'submit')
  }

  onSuccess(event) {
    let [data, status, xhr] = event.detail
    this.resultTarget.outerHTML = xhr.response
  }
}
