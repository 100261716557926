import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "overview"
  ]

  onSuccess(event) {
    let [data, status, xhr] = event.detail
    this.overviewTarget.innerHTML = xhr.response
  }

  onError(event) {
    let [data, status, xhr] = event.detail
  }
}
