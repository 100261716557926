import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "delete", "deleteTarget" ]

  launchDeleteModal(event) {
    event.preventDefault()
    let deleteModalController = this.application.getControllerForElementAndIdentifier(
      this.deleteTarget,
      "modal"
    )
    let deleteTarget = event.target.closest('[data-target="modals.deleteTarget"]')
    let deleteTargetId = deleteTarget.dataset.modalsDeleteTargetIdValue
    if (event.target.nodeName == 'I') {
      deleteModalController.actionTarget.href = event.target.closest('span').dataset.modalsDeleteUrlValue
    } else {
      deleteModalController.actionTarget.href = event.target.dataset.modalsDeleteUrlValue
    }
    deleteModalController.open(deleteTargetId)
  }

  removeAfterDelete(deleteTarget) {
    deleteTarget.remove()
  }
}
