import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    this.boundBeforeInsert = this.beforeInsert.bind(this)
    $(this.insertionNode).on('cocoon:before-insert', this.boundBeforeInsert)
  }

  beforeInsert(event, insertedItem, originalEvent) {
    if (this.lastInput.value === "") {
      event.preventDefault()
    }
  }

  get insertionNode() {
    return this.element.dataset.notificationRecipientsAssociationInsertionNode;
  }

  get inputs() {
    return this.element.querySelectorAll('input[type="email"]')
  }

  get lastInput() {
    return this.inputs[this.inputs.length - 1]
  }
}
