import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "cardNumber",
    "cardExpiry",
    "cardCvc",
    "errors",
    "form"
  ]

  initialize() {
    super.initialize()
    this.errorCallback = this.errorCallback.bind(this)
    this.stripeSubmit = this.stripeSubmit.bind(this)
    this.stripeTokenHandler = this.stripeTokenHandler.bind(this)
  }

  connect() {
    if (!this.editMode) {
      this.stripeInit()
    }
  }

  removeEditMode(event) {
    event.preventDefault()
    if (this.editMode) {
      this.element.dataset.stripeEditModeValue = 'false'
      this.stripeInit()
    }
  }

  stripeInit() {
    // Create a Stripe client.
    this.stripe = Stripe(this.stripePublishableKey)
    this.createElements()
    this.addEventListeners()
  }

  createElements() {
    // Create an instance of Elements.
    this.elements = this.stripe.elements()

    this.cardNumberElement = this.elements.create('cardNumber')
    this.cardNumberElement.mount(this.cardNumberTarget)

    this.cardExpiryElement = this.elements.create('cardExpiry')
    this.cardExpiryElement.mount(this.cardExpiryTarget)

    this.cardCvcElement = this.elements.create('cardCvc')
    this.cardCvcElement.mount(this.cardCvcTarget)
  }

  addEventListeners() {
    this.cardNumberElement.addEventListener('change', this.errorCallback)
    this.cardExpiryElement.addEventListener('change', this.errorCallback)
    this.cardCvcElement.addEventListener('change', this.errorCallback)

    this.formTarget.addEventListener('submit', this.stripeSubmit)
  }

  errorCallback(event) {
    if (event.error) {
      this.errorsTarget.textContent = event.error.message
    } else {
      this.errorsTarget.textContent = ''
    }
  }

  stripeSubmit(event) {
    event.preventDefault()
    console.log(this.errorTarget)
    this.stripe.createToken(this.cardNumberElement).then(result => {
      console.log(result.error)
      if (result.error) {
        this.errorTarget.textContent = result.error.message
      } else {
        // Send the token to your server.
        this.stripeTokenHandler(result.token)
      }
    })
  }

  stripeTokenHandler(token) {
   // Insert the token ID into the form so it gets submitted to the server
   var hiddenInput = document.createElement('input')
   hiddenInput.setAttribute('type', 'hidden')
   hiddenInput.setAttribute('name', 'stripeToken')
   hiddenInput.setAttribute('value', token.id)
   this.formTarget.appendChild(hiddenInput)

   // Submit the form
   this.formTarget.submit()
 }

  get stripePublishableKey() {
    return this.element.dataset.stripePublishableKeyValue
  }

  get editMode() {
    return this.element.dataset.stripeEditModeValue == 'true'
  }
}
