import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "options", "input", "hiddenField", "button" ]

  initialize() {
    if (this.hasButtonTarget) {
      this.buttonTarget.disabled = true;
    }
  }

  toggleDropdown() {
    // debugger
    if (this.optionsTarget.classList.contains("custom-dropdown-hide")) {
      this.optionsTarget.classList.remove("custom-dropdown-hide");
    } else {
      this.optionsTarget.classList.add("custom-dropdown-hide")
    }
  }

  closeDropdown() {
    this.optionsTarget.classList.add("custom-dropdown-hide")
  }

  select(event) {
    let accountName = event.currentTarget.getAttribute("account");
    let url = event.currentTarget.getAttribute("url")
    this.inputTarget.innerHTML = accountName;
    this.toggleDropdown();
    this.inputTarget.setAttribute("url", url)
    this.enableButton();
  }

  redirect(event) {
    let newUrl = this.inputTarget.getAttribute("url")
    event.preventDefault();
    window.location.replace(newUrl)
  }

  selectDate(event) {
    let accountName = event.currentTarget.getAttribute("account");
    let url = event.currentTarget.getAttribute("url")
    this.hiddenFieldTarget.value = url;
    this.toggleDropdown();
    this.inputTarget.innerHTML = accountName;
    this.inputTarget.setAttribute("url", url)
  }

  enableButton() {
    this.buttonTarget.disabled = this.inputTarget.innerHTML === '';
  }
}
