import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "modal", "deleteTarget" ]

  launchModal(event) {
    event.preventDefault()

    let modalController = this.application.getControllerForElementAndIdentifier(
      this.modalTarget,
      "modal"
    )
    
    modalController.open()
  }

  removeAfterDelete(deleteTarget) {
    deleteTarget.remove()
  }
}
