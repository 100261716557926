import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "form",
    "startDate",
    "endDate",
    "startDateContainer",
    "endDateContainer",
    "selectDate",
    "overview",
    "hiddenField"
  ]

  connect() {
    this.startDateContainerTarget.classList.add('hidden')
    this.endDateContainerTarget.classList.add('hidden')
    this.hiddenFieldTarget.value = "today";

    $(this.element).on('date_filtered_form_controller.state', (event, callback) => {
      callback(this)
    }).bind(this)

    let closestNavigationTab = this.element.closest('[data-target*="navigation-tabs"]')
    if (!!closestNavigationTab) {
      if (!(closestNavigationTab.style.display == 'none')) {
        this.fetch()
      }
    } else {
      this.fetch()
    }
  }

  refresh(event) {
    event.preventDefault()
    if (event.target.value == "custom" || this.hiddenFieldTarget.value == "custom") {
      this.startDateContainerTarget.classList.remove('hidden')
      this.endDateContainerTarget.classList.remove('hidden')
    } else {
      this.startDateTarget.value = null;
      this.endDateTarget.value = null;
      this.fetch()
      this.startDateContainerTarget.classList.add('hidden')
      this.endDateContainerTarget.classList.add('hidden')
    }
  }

  fetch() {
    Rails.fire(this.formTarget, "submit")
  }

  onSuccess(event) {
    let [data, status, xhr] = event.detail
    if (this.hasOverviewTarget) {
      this.overviewTarget.innerHTML = xhr.response
    }
  }

  onError(event) {
    let [data, status, xhr] = event.detail
  }
}
