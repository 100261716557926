import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "source", "buttonText", "icon" ]

  connect() {
    this.element.classList.add("clipboard")
    if (document.queryCommandSupported("copy")) {
      this.element.classList.add("clipboard--supported")
    }
  }

  // Function to override on copy.
  copy(event) {
    event.preventDefault()
    this.sourceTarget.select()
    document.execCommand("copy")
    this.copied(event)
  }

  // Function to override when to input is copied.
  copied(event) {
    this.buttonTextTarget.textContent = this.buttonTextTarget.textContent.replace(/copy/gi, 'Copied')
    // event.target.closest("button").classList.add("clipboard--copied")
    event.target.closest('button').classList.remove('button-grey')
    event.target.closest('button').classList.add('button-green')
    this.iconTargets.forEach(icon => icon.classList.toggle('hidden'))
  }
}
