import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "button", "content", "icon" ]

  connect() {

  }

  open(event) {
    event.preventDefault()
    this.contentTarget.classList.remove('hidden')
    this.iconTarget.classList.remove('i-rotate')
    // this.iconTarget.classList.add('icon-down')
  }

  close(event) {
    event.preventDefault()
    this.contentTarget.classList.add('hidden')
    this.iconTarget.classList.add('i-rotate')
    // this.iconTarget.classList.remove('icon-down')
  }

  toggle(event) {
    event.preventDefault()
    this.contentTarget.classList.toggle('hidden')
    this.toggleIcon()
  }

  toggleIcon() {
    // this.iconTarget.classList.toggle('icon-down')
    this.iconTarget.classList.toggle('i-rotate')
  }
}
