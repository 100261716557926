import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "form", "input", "resultsContainer" ]

  connect() {
    this.searching = false
  }

  onSuccess(event) {
    let [data, status, xhr] = event.detail
    this.searching = !this.searching
    this.resultsContainerTarget.innerHTML = xhr.response
  }

  search() {
    if (!this.searching) {
      if (this.inputTarget.value.length > 3) {
        this.searching = !this.searching
        Rails.fire(this.formTarget, 'submit')
      } else {
        this.clearResults()
      }
    }
  }

  clearResults(event) {
    if (event instanceof FocusEvent && event.relatedTarget instanceof HTMLAnchorElement) {
      event.preventDefault()
    } else {
      this.resultsContainerTarget.innerHTML = ""
    }
  }
}
