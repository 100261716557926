import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "editButton",
    "icon",
    "iconText"
  ]

  connect() {

  }

  toggleEdit(event) {
    event.preventDefault()

    if (this.editButtonTarget.dataset.editing == "false") {
      this.openEdit()
    } else {
      this.closeEdit()
    }
  }

  openEdit() {
    this.editButtonTarget.classList.remove("button-green")
    this.editButtonTarget.classList.add("btntwo-grey")
    this.editButtonTarget.dataset.editing = "true"
    this.iconTarget.classList.remove("icon-edit")
    this.iconTarget.classList.add("icon-minus-bordered")
    this.iconTextTarget.textContent = "Cancel"

    fetch(this.element.dataset.responseUrlValue)
      .then(response => response.text())
      .then(html => this.loadEditForm(html))
  }

  closeEdit() {
    this.editButtonTarget.classList.add("button-green")
    this.editButtonTarget.classList.remove("btntwo-grey")
    this.editButtonTarget.dataset.editing = "false"
    this.iconTarget.classList.add("icon-edit")
    this.iconTarget.classList.remove("icon-minus-bordered")
    this.element.nextElementSibling.remove()
    this.iconTextTarget.textContent = "Edit"
  }

  loadEditForm( html) {
    this.element.insertAdjacentHTML('afterend', html)
  }


  onSuccess(event) {
    let [data, status, xhr] = event.detail
    this.closeEdit()
    this.element.outerHTML = xhr.response
  }

  onError(event) {
    let [data, status, xhr] = event.detail
  }
}
