import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
    toastr.options = {
      "closeButton": true,
      "positionClass": "toast-bottom-left"
    }
  }

  onSuccess(event) {
    if (!!event.target.dataset.toastrSuccess) {
      toastr.success(event.target.dataset.toastrSuccess, 'Success!')
    }
  }

  onError(event) {
    if (event.target.dataset.toastrError) {
      toastr.error(event.target.dataset.toastrError, 'Error')
    } else {
      let [data, status, xhr] = event.detail
      let message = data.querySelector('body').innerText
      toastr.error(message, 'Error')
    }
  }
}
