import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "cards", "searchInput", "searchSort", "searchForm" ]

  connect() {
  }

  onSuccess(event) {
    let [data, status, xhr] = event.detail
    this.cardsTarget.innerHTML = xhr.response
  }

  search() {
    let i = this.searchInputTarget.value.length
    if (i == 0 || i > 3) {
      Rails.fire(this.searchFormTarget, 'submit')   
    }
  }
}
