import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "button", "buttonText", "form", "editIcon", "cancelIcon" ]

  initialize() {
    this.updateButton = this.updateButton.bind(this)
    this.editing = false
  }

  connect() {
    this.questionId = this.element.dataset.questionIdValue
    $(document).on(`question_controller.state-${this.questionId}`, (event, callback) => {
      callback(this)
    }).bind(this)
  }

  toggleEdit(event) {
    event.preventDefault()
    this.editing = !this.editing
    this.updateButton()
  }

  cancel(event) {
    event.preventDefault()
    this.element.classList.add('hidden')
    let questionId = event.target.closest('[data-controller="question"]').dataset.questionForm
    $(document).trigger(`question_controller.state-${questionId}`, questionController => {
      questionController.editing = !questionController.editing
      questionController.updateButton()
    })
  }

  delete() {
    $(document).trigger('pages_controller.state', pagesController => pagesController.refresh())
  }

  updateButton() {
    if (this.editing) {
      this.buttonTextTarget.innerHTML = 'Cancel'
      this.buttonTarget.classList.remove('btn-green')
      this.buttonTarget.classList.remove('button-green')
      this.buttonTarget.classList.add('btn-grey')
      this.buttonTarget.classList.add('button-grey')
      this.editIconTarget.classList = "hidden";
      this.cancelIconTarget.classList = "";
    } else {
      this.buttonTextTarget.innerHTML = 'Edit'
      this.buttonTarget.classList.remove('btn-grey')
      this.buttonTarget.classList.remove('button-grey')
      this.buttonTarget.classList.add('btn-green')
      this.buttonTarget.classList.add('button-green')
      this.editIconTarget.classList = "";
      this.cancelIconTarget.classList = "hidden";
    }
  }

  onSuccess(event) {
    let [data, status, xhr] = event.detail
    toastr.success("Question saved successfully.");
  }

  onError(event) {
    let [data, status, xhr] = event.detail
    toastr.success("There was an error saving the Question.");
  }
}
