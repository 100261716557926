import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [  ]

  connect() {
    console.log("Duplicate form connected!")
  }

  onSuccess(event) {
    let [data, status, xhr] = event.detail
    this.element.insertAdjacentHTML('afterend', xhr.response)
  }

  onError(event) {
    let [data, status, xhr] = event.detail
    toastr.error('Domain already has pages. Cannot be duplicated')
  }
}
