import { Controller } from "stimulus"
import EditorJS from '@editorjs/editorjs'
import Header from '@editorjs/header'
import SimpleImage from '@editorjs/simple-image'
import List from '@editorjs/list'
import Delimiter from '@editorjs/delimiter'
import LinkTool from '@editorjs/link'
import Embed from '@editorjs/embed'
import Table from '@editorjs/table'

export default class extends Controller {
  static targets = [ "editor", "formatAlign", "input" ]

  initialize() {
    this.editor = new EditorJS({
  	  holder: 'markup-content-editor',
  	  tools: {
  	    header: {
  	      class: Header,
  	      inlineToolbar: ['link'],
  	      config: {
  	        placeholder: 'Header'
  	      },
  	      shortcut: 'CMD+SHIFT+H'
  	    },
  	    image: SimpleImage,
  	    list: {
  	      class: List,
  	      inlineToolbar: true,
  	      shortcut: 'CMD+SHIFT+L'
  	    },
  	    delimiter: Delimiter,
  	    linkTool: LinkTool,
  	    embed: Embed,
  	    table: {
  	      class: Table,
  	      inlineToolbar: true,
  	      shortcut: 'CMD+ALT+T'
  	    }
  	  },
  	  data: this.data
  	});
  }

  connect() {
  }

  submit(event) {
    this.editor.save().then(this.save.bind(this), error => {
      console.log('Saving failed: ', error)
    })
  }

  save(data) {
    this.inputTarget.value = JSON.stringify(data)
  }

  changeAlignment(event) {
    let newAlignment = event.target.dataSet.formatAlign
    this.editorTarget.css('text-align', newAlignment)
    this.formatAlignTarget.value = newAlignment
  }

  get data() {
    try {
      return JSON.parse(this.editorTarget.dataset.editorData)
    } catch {
      return {}
    }
  }
}
