import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "action",
    "headerSubtitle",
    "requestBody",
    "responseBody",
    "deleteButton"
  ]

  open(deleteTargetId = null, successMessage = "", errorMessage = "") {
    this.element.dataset.deleteTargetId = deleteTargetId
    this.element.dataset.successMessage = successMessage
    this.element.dataset.errorMessage = errorMessage

    document.body.classList.add("modal-open")
    this.element.classList.toggle("modal--show")
    this.element.classList.add("show")
    let backdrop = document.body.querySelector('[data-target="modal.backdrop"]')
    backdrop.classList.toggle("modal-backdrop")
    backdrop.classList.toggle("show")
  }

  close() {
    document.body.classList.remove("modal-open")
    this.element.classList.toggle("modal--show")
    this.element.classList.remove("show")
    let backdrop = document.body.querySelector('[data-target="modal.backdrop"]')
    backdrop.classList.toggle("modal-backdrop")
    backdrop.classList.toggle("show")
  }

  onDeleteSuccess(event) {
    let [data, status, xhr] = event.detail
    this.close()
    this.removeNode()
    toastr.success(this.successMessage, "Success!")
    console.log("Item deleted successfully.")
  }

  onDeleteError(event) {
    let [data, status, xhr] = event.detail
    this.close()
    toastr.error(this.errorMessage, "Error")
    console.error("Item was not deleted successfully.")
  }

  removeNode() {
    if (this.element.dataset.deleteTargetId !== "null") {
      document.body.querySelector(`[data-modals-delete-target-id-value="${this.element.dataset.deleteTargetId}"]`).remove()
    }
  }

  get successMessage() {
    return this.element.dataset?.successMessage || ""
  }

  get errorMessage() {
    return this.element.dataset?.errorMessage || ""
  }
}
