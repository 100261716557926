import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "loader",
    "empty",
    "graph"
  ]

  onSuccess(event) {
    let [data, status, xhr] = event.detail

    let jsonData = JSON.parse(xhr.response)
    this.createGraph(jsonData)
  }

  createGraph(data) {
    if (data.stats.length > 0) {
      this.hideEmpty()
      this.showLoader()
      this.graphTarget.innerHTML = ""

      Morris.Line({
        element: this.graphTarget,
        data: data.stats,
        xkey: 'date',
        ykeys: ['total_count', 'completed_count'],
        labels: ['Total visits', 'Leads generated'],
        lineColors: ["#828EA8", "#4BD1BB"],
        pointSize: 0,
        continuousLine: true
      })
      this.graphTarget.classList.remove('hidden')
    }
    if (data.stats.length == 0) {
      this.showEmpty()
    }
  }

  onError(event) {
    let [data, status, xhr] = event.detail
    this.graphTarget.innerHTML = ""
    this.showEmpty()
  }

  showLoader() {
    this.loaderTarget.classList.remove('hidden')
    this.emptyTarget.classList.add('hidden')
    this.graphTarget.classList.add('hidden')
  }

  hideLoader() {
    this.loaderTarget.classList.add('hidden')
  }

  showEmpty() {
    this.emptyTarget.classList.remove('hidden')
    this.loaderTarget.classList.add('hidden')
    this.graphTarget.classList.add('hidden')
  }

  hideEmpty() {
    this.emptyTarget.classList.add('hidden')
  }
}
