import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "modal", "deleteTarget" ]

  launchModal(event) {
    event.preventDefault()

    let deleteModalController = this.application.getControllerForElementAndIdentifier(
      this.modalTarget,
      "modal"
    )
    let deleteTarget = event.target.closest('[data-target="delete-modal.deleteTarget"]')
    let deleteTargetId = deleteTarget?.dataset?.modalsDeleteTargetIdValue
    let deleteSuccessMessageValue = deleteTarget?.dataset?.modalsDeleteSuccessMessageValue
    let deleteErrorMessageValue = deleteTarget?.dataset?.modalsDeleteErrorMessageValue
    let skipAjaxSuccess = event.currentTarget.dataset.modalsSkipAjaxSuccess == '1'

    deleteModalController.actionTarget.href = event.currentTarget.dataset?.modalsDeleteUrlValue

    if (skipAjaxSuccess) {
      delete deleteModalController.actionTarget.dataset.action
    }

    let deleteMessage = event.currentTarget.dataset?.modalsDeleteMessageValue
    if (!!deleteMessage) {
      deleteModalController.headerSubtitleTarget.textContent = deleteMessage
    }

    let deleteButtonText = event.currentTarget.dataset?.modalsDeleteButtonTextValue
    if (!!deleteButtonText) {
      deleteModalController.deleteButtonTarget.textContent = deleteButtonText
    }

    deleteModalController.open(deleteTargetId, deleteSuccessMessageValue, deleteErrorMessageValue)
  }

  removeAfterDelete(deleteTarget) {
    deleteTarget.remove()
  }
}
