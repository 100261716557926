import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "anchors" ]

  connect() {
    let anchors;
    if (this.isSettingsPage) {
      anchors = this.anchorsTargets.filter(el => this.settingsPageRegex.test(el.href))
    } else if (this.isIntegrationsPage) {
      anchors = this.anchorsTargets.filter(el => this.integrationsPageRegex.test(el.href))
    } else if (this.isStylesPage) {
      anchors = this.anchorsTargets.filter(el => this.stylesPageRegex.test(el.href))
    }
    anchors.filter(el => !/#/.test(el.href)).forEach(el => {
      el.classList.add("active")
    })
  }

  get settingsPageRegex() {
    return /\/admin\/domains\/\d+\/(edit|landing_pages|redirects)/ig;
  }

  get integrationsPageRegex() {
    return /\/admin\/domains\/\d+\/integrations/ig;
  }

  get stylesPageRegex() {
    return /\/admin\/domains\/\d+\/styles/ig;
  }

  get isSettingsPage() {
    return this.settingsPageRegex.test(this.urlPathname)
  }

  get isIntegrationsPage() {
    return this.integrationsPageRegex.test(this.urlPathname)
  }

  get isStylesPage() {
    return this.stylesPageRegex.test(this.urlPathname)
  }

  get urlPathname() {
    return window.location.pathname;
  }
}
