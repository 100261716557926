import { TabsController } from 'stimulus-tabs'

export default class extends TabsController {
  initialize() {
    this.validAnchors = this.element.dataset.tabs.split(' ')
    super.initialize()
  }

  connect() {
    let anchor = window.location.hash.slice(1)
    if (this.validAnchors.includes(anchor)) {
      this._tabState.setSelectedTab(anchor, false)
    }
    super.connect()
  }

  selected() {
  }
}
