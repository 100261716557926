import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ ]

  switchAction(event) {
    let value = event.currentTarget.value
    this.element.action = value
  }

  redirect(event) {
    event.preventDefault()
    window.location.replace(this.element.action)
  }
}
