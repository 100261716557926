import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "newRow" ]

  initialize() {
    this.updateForm = this.updateForm.bind(this)
    this.indexUrl = this.element.dataset.optionsIndexUrlValue
    this.newUrl = this.element.dataset.optionsNewUrlValue
  }

  connect() {
  }

  new() {
    this.newUrl = this.newUrl.replace('?is_upload=true','');
    fetch(this.newUrl)
    .then(response => response.text())
    .then(html => {
        this.updateForm(html)
      })
  }
  upload() {
    if (!this.newUrl.includes("?is_upload=true")){
      this.newUrl = this.newUrl + "?is_upload=true"
    }
    fetch(this.newUrl)
    .then(response => response.text())
    .then(html => {

        this.updateForm(html)
      })
  }

  toggleEdit(event) {
    event.preventDefault()
    let optionId = event.target.closest('[data-controller="option"]').dataset.optionIdValue
    let form = this.element.querySelector(`[data-option-form~="${optionId}"]`)

    if (form.classList.contains('hidden')) {
      form.classList.remove('hidden')
    } else {
      form.classList.add('hidden')
    }
  }

  updateForm(html, element = null) {
    if (element === null) {
      this.newRowTarget.insertAdjacentHTML('beforebegin', html)
    } else {
      element.innerHTML = html
    }
  }

  refresh() {
    console.log(this.indexUrl )
    fetch(this.indexUrl)
      .then(response => response.text())
      .then(html => this.refreshContainer(html))
  }

  refreshContainer(html) {
    this.element.outerHTML = html
  }
}
