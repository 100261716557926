import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "editIcon", "cancelIcon" ]

  initialize() {
  }

  connect() {
  }

  toggleEdit(event) {
    this.editIconTarget.classList.toggle('hidden')
    this.cancelIconTarget.classList.toggle('hidden')
  }
}
