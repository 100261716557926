import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["picker", "field"]

  initialize() {
    super.initialize()
    this.colorValue = this.element.dataset.colorPickerColorValue
  }

  connect() {
    this.colorValueChanged()
  }

  select(e) {
    if (/^\#[a-fA-F0-9]{6}$/.test(e.target.value)) {
      this.colorValue = e.target.value
      this.colorValueChanged()
    }
  }

  colorValueChanged() {
    this.pickerTarget.value = this.colorValue
    this.fieldTarget.value = this.colorValue
  }
}
