import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []

  onSuccess(event) {
    let [data, status, xhr] = event.detail
    if (!! this.surveyController) {
      this.surveyController.onSuccess(event)
    }
  }

  onError(event) {
    let [data, status, xhr] = event.detail
    if (!! this.surveyController) {
      this.surveyController.onError(event)
    }
  }

  get surveyController() {
    return this.application.getControllerForElementAndIdentifier(
      this.componentRootElement.previousElementSibling,
      "survey"
    )
  }

  get componentRootElement() {
    return this.element.closest('tr')
  }
}
