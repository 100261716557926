import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "filePreview", "fileName" ]

  setFileName(event) {
    let file = event.currentTarget.files[0]
    let text
    if (file) {
      text = file.name
    } else {
      text = "No file chosen"
    }
    this.fileNameTarget.innerText = text
    this.setPreview(event.currentTarget)
  }

  setPreview(input) {
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(input.files[0])
      reader.onload = e => {
        this.filePreviewTarget.src = e.target.result
      }
    }
  }
}
