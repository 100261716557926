import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["warning", "checkbox"];

    connect() {
        console.log("toc connected")
        console.log(this.warningTarget)
    }

    checkTos() {
        (!this.checkboxTarget.querySelectorAll("input")[1].checked) ? this.warningTarget.classList.remove("tos-hidden") : this.warningTarget.classList.add("tos-hidden")
        
    }
}
