import ChartController from "./chart_controller"

export default class extends ChartController {
  createGraph(data) {
    if (this.isDataSetEmpty(data)) {
      this.showEmpty()
      return
    }
    if (data.stats.length > 0) {
      this.hideEmpty()
      this.showLoader()
      this.graphTarget.innerHTML = ""
      this.graphTarget.classList.remove('hidden')

      Morris.Line({
        element: this.graphTarget,
        data: data.stats,
        xkey: 'date',
        ykeys: ['conversion_rate'],
        labels: ['Conversion Rate'],
        lineColors: ["#4BD1BB"],
        postUnits: '%',
        pointSize: 0,
        continuousLine: true
      })
    }
  }

  isDataSetEmpty(data) {
    let conversionSet = new Set(this.conversionRates(data))
    if (conversionSet.size < 1 || (conversionSet.size == 1 && conversionSet.has(0))) {
      return true
    }

    return false
  }

  conversionRates(data) {
    return data.stats.map(stat => stat.conversion_rate)
  }
}
