import { TabsController } from 'stimulus-tabs'

export default class NavigationTabsController extends TabsController {
  initialize() {
    this.validAnchors = this.element.dataset.tabs.split(' ')
    super.initialize()
  }

  connect() {
    let anchor = window.location.hash.slice(1)
    if (this.validAnchors.includes(anchor)) {
      this._tabState.setSelectedTab(anchor, false)
    }
    if (this.selectedTab?.text == "Billing") {
      this.initializeStripe();
    }
    this.addActiveNavigationTabClass()
    super.connect()
  }

  updateUrl(event) {
    history.pushState({}, window.document.title, event.currentTarget.href)
  }

  selected() {
    if (this.selectedTab.text == "Billing") {
      this.initializeStripe();
    }
    if (!!this.dateFilteredFormElement) {
      $(this.dateFilteredFormElement).trigger(`date_filtered_form_controller.state`, dateFilteredFormController => {
        dateFilteredFormController.fetch()
      })
    }
    this.addActiveNavigationTabClass()
  }

  addActiveNavigationTabClass() {
    let anchor = window.location.hash.slice(1)
    if (anchor && this.validAnchors.includes(anchor)) {
      this.element.querySelector(`[href="#${anchor}"]`)?.classList?.add('active')
      this.element.querySelector(`[data-href="${anchor}"]`)?.classList?.add('active')
    } else {
      this.element.querySelector(`[href="#${this._initialTabName}"]`)?.classList?.add('active')
      this.element.querySelector(`[data-href="${this._initialTabName}"]`)?.classList?.add('active')
    }
  }

  initializeStripe() {
    var stripe = Stripe('pk_test_51IJMLSDlwsgblGeS2NDDOXXMAGtM0fAkKocUVDcKGuLcb8pXsulnZ6QJcBGoE8pfQ64MmtExziQciQeq9nCyMUMy00rgnSbOT7');
    var elements = stripe.elements();

    // Custom styling can be passed to options when creating an Element.
    var style = {
        base: {
        // Add your base input styles here. For example:
        fontSize: '16px',
        color: '#32325d'
        },
    };

    // Create an instance of the card Element.
    var card = elements.create('card', {style: style});


    // Add an instance of the card Element into the `card-element` <div>.
    card.mount('#card-element');
  }

  get tabName() {
    return this._tabState.selectedTabName
  }

  get selectedTab() {
    return document.querySelector(`div#${this.tabName}`)
  }

  get dateFilteredFormElement() {
    return this.selectedTab.querySelectorAll('[data-controller*="date-filtered-form"]')
  }
}
