import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "form",
    "selectDate",
    "startDate",
    "endDate",
    "startDateContainer",
    "endDateContainer",
    "overview",
    "counter",
    "empty",
    "input",
    "hiddenField",
    "page"
  ]


  connect() {
    let queryString = window.location.search;
    let searchParams = new URLSearchParams(queryString);
    this.inputTarget.innerHTML = this.date_range[searchParams.get("date_range") || "today"];

    if (searchParams.get('date_range') == 'custom') {
      this.startDateContainerTarget.classList.remove('hidden')
      this.endDateContainerTarget.classList.remove('hidden')
      this.startDateTarget.value = searchParams.get('start_date')
      this.endDateTarget.value = searchParams.get('end_date')
    } else {
      this.startDateContainerTarget.classList.add('hidden')
      this.endDateContainerTarget.classList.add('hidden')
      this.hiddenFieldTarget.value = 'today'
    }

    if (queryString) {
      this.hiddenFieldTarget.value = searchParams.get('date_range')
    };
    this.fetch()
  }

  refresh(event) {
    event.preventDefault()
    if (event.target.value == "custom" || this.hiddenFieldTarget.value == "custom")  {
      this.startDateContainerTarget.classList.remove('hidden')
      this.endDateContainerTarget.classList.remove('hidden')
    } else {
      this.startDateTarget.value = "";
      this.endDateTarget.value = "";
      this.pageTarget.value = "1";
      this.fetch()
      this.startDateContainerTarget.classList.add('hidden')
      this.endDateContainerTarget.classList.add('hidden')
    }
  }

  fetch() {
    Rails.fire(this.formTarget, "submit")
  }

  onSuccess(event) {
    let [data, status, xhr] = event.detail
    this.overviewTarget.innerHTML = xhr.response
    let newCountElement = this.element.querySelector('span[data-target="traffic.newCount"]')
    this.counterTarget.innerHTML = newCountElement.innerHTML
    if (newCountElement.innerHTML == "0") {
      this.emptyTarget.classList.remove("hidden")
      this.overviewTarget.classList.add("hidden")
    } else {
      this.emptyTarget.classList.add("hidden")
      this.overviewTarget.classList.remove("hidden")
    }
    newCountElement.remove()
  }

  onError(event) {
    let [data, status, xhr] = event.detail
  }

  downloadCsv(event) {
    let url = new URL(event.target.href);

    if (this.selectDateTarget.value == 'custom') {
      url.searchParams.set('start_date', this.startDateTarget.value)
      url.searchParams.set('end_date', this.endDateTarget.value)
    } else {
      url.searchParams.set('date_range', this.selectDateTarget.value)
    }

    event.target.href = url.toString();
  }

  clearPagination(event) {
    this.pageTarget.value = 1;
  }

  get date_range() {
    return {
      'all_time' : 'All time',
      'last_week' : 'Last week',
      'today' : 'Today',
      'last_month' : 'Last month',
      'last_three_month' : 'Last 3 month',
      'last_year' : 'Last year',
      'custom' : 'Custom'
    }
  }
}
