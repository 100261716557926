import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
    if (!!CodeMirror) {
      let instance = CodeMirror.fromTextArea(this.element, {
        lineNumbers: true,
        lineWrapping: true,
        autoRefresh: true,
        theme: "blackboard",
        mode: "htmlmixed"
      })
      instance.on('change', (instance, changeObj) => {
        $(document).trigger('check_save.enableButton', () => {})
      })
    }
  }

  onLoad(event) {

  }
}
