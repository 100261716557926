import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "modal"
  ]

  launchModal(event) {
    event.preventDefault()
    this.initModalValues(event)
    let modalController = this.application.getControllerForElementAndIdentifier(
      this.modalTarget,
      "modal"
    )
    modalController.headerSubtitleTarget.textContent = this.headerSubtitleValue
    modalController.requestBodyTarget.textContent = this.requestBodyValue
    modalController.responseBodyTarget.textContent = this.responseBodyValue
    modalController.open()
  }

  initModalValues(event) {
    this.headerSubtitleValue = event.target.closest(".response").dataset.requestResponseModalHeaderSubtitleValue
    this.requestBodyValue = event.target.closest(".response").dataset.requestResponseModalRequestBodyValue
    this.responseBodyValue = event.target.closest(".response").dataset.requestResponseModalResponseBodyValue
  }
}
