import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "form",
    "selectDate",
    "startDate",
    "endDate",
    "startDateContainer",
    "endDateContainer",
    "overview",
    "counter",
    "empty",
    "input",
    "hiddenField",
    "page"
  ]


  connect() {
    let queryString = window.location.search;
    let searchParams = new URLSearchParams(queryString);
    const d = new Date();
    let mon = d.getUTCMonth() + 1;
    let year = d.getUTCFullYear();
    
    this.inputTarget.innerHTML = this.month[searchParams.get("month") || mon];
    this.inputTargets[1].innerHTML = this.month[searchParams.get("all_month") || mon];
    this.inputTargets[2].innerHTML = this.year[searchParams.get("year") || year];
    
    
      this.hiddenFieldTarget.value = mon
      this.hiddenFieldTargets[1].value = mon
      this.hiddenFieldTargets[2].value = year
      
      if (queryString) {
        this.hiddenFieldTarget.value = searchParams.get('month')
        this.hiddenFieldTargets[1].value = searchParams.get('all_month')
        this.hiddenFieldTargets[2].value = searchParams.get('year')
        var current_year = new Date().getFullYear()
      var selected_year = document.getElementById("selected_year").innerHTML
      var months = document.getElementById("months");
      var all_months = document.getElementById("all_months");

      if(selected_year < current_year) {
        months.classList.add("d-none");
        all_months.classList.remove("d-none");
      }else{
        months.classList.remove("d-none");
        all_months.classList.add("d-none");
      }
    }
    this.fetch()
  }

  refresh(event) {
    event.preventDefault()
      this.pageTarget.value = "1";

      var current_year = new Date().getFullYear()
      var selected_year = document.getElementById("selected_year").innerHTML
      var months = document.getElementById("months");
      var all_months = document.getElementById("all_months");

      if(selected_year < current_year) {
        months.classList.add("d-none");
        all_months.classList.remove("d-none");
      }else{
        months.classList.remove("d-none");
        all_months.classList.add("d-none");
      }

      this.fetch()
    
  }

  fetch() {
    Rails.fire(this.formTarget, "submit")
  }
  
  setAccount(){
    this.pageTarget.value = "1";
    this.fetch()

  }

  onSuccess(event) {
    let [data, status, xhr] = event.detail


    this.overviewTarget.innerHTML = xhr.response
    let newCountElement = this.element.querySelector('span[data-target="reports.newCount"]')
    this.counterTarget.innerHTML = newCountElement.innerHTML
    if (newCountElement.innerHTML == "0") {
      this.emptyTarget.classList.remove("hidden")
      this.overviewTarget.classList.add("hidden")
    } else {
      this.emptyTarget.classList.add("hidden")
      this.overviewTarget.classList.remove("hidden")
    }
    newCountElement.remove()
  }

  onError(event) {
    let [data, status, xhr] = event.detail
  }

  downloadCsv(event) {
    let url = new URL(event.target.href);
    
    var acc_id = document.getElementById("acc_id")?.innerHTML;
    var year = document.getElementById("year").value
    var current_year = new Date().getFullYear()  
    var month = year < current_year ? document.getElementById('all_month').value : document.getElementById('month').value
  
    url.searchParams.set('month', month)
    url.searchParams.set('year', year)
    url.searchParams.set('acc_id', acc_id)  
    console.log(url)
    
    event.target.href = url.toString();
  }

  clearPagination(event) {
    this.pageTarget.value = 1;
  }

  get month() {
    return {
       '1' : 'Jan',
       '2' : 'Feb',
       '3' : 'Mar',
       '4' : 'Apr',
       '5' : 'May',
       '6' : 'Jun',
       '7' : 'Jul',
       '8' : 'Aug',
       '9' : 'Sep',
      '10' : 'Oct',
      '11' : 'Nov',
      '12' : 'Dec',
    }
  }

  get year() {
    return {
      '2022' : '2022',
      '2021' : '2021',
      '2020' : '2020',
      '2019' : '2019',
      '2018' : '2018',
      '2017' : '2017',
    }
  }
}
