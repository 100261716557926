import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
    toastr.options = {
      "closeButton": true,
      "positionClass": "toast-bottom-left",
      "onclick": this.test
    }
  }

  connect() {
    toastr[this.level](this.message, this.title)
  }

  test() {
    debugger
  }

  get title() {
    return {
      'success': 'Success!',
      'warning': 'Warning',
      'error': 'Error'
    }[this.level]
  }

  get level() {
    return this.element.dataset.autoToastrLevelValue
  }

  get message() {
    return this.element.dataset.autoToastrMessageValue
  }
}
