import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "userForm", "user", "icon" ]

  initialize() {
    super.initialize()
    this.iconTypes = ["plus", "minus", "loader", "finished", "error"]
  }

  addUser(event) {
    event.preventDefault()
    if (this.userTargetsLength == 1 ||
        (this.userTargetsLength > 1 && this.userTargets[this.userTargetsLength - 1]?.value != "")) {

      let domNode = event.currentTarget.closest('.invitable-user')
      let newNode = domNode.cloneNode(true)
      newNode.querySelector('input[type="email"]').value = ""
      domNode.insertAdjacentElement('afterend', newNode)

      // let index = this.iconTargets.indexOf(event.currentTarget)
      this.showMinus(this.userTargetsLength - 2)
      // this.showPlus(this.userTargetsLength - 1)
    }
  }

  removeUser(event) {
    event.preventDefault()
    if (this.userTargetsLength == 1) {
      let index = this.iconTargets.indexOf(event.currentTarget.parentElement)
      this.userTargets[index].value = ""
      this.showPlus(index)
    } else {
      let domNode = event.currentTarget.closest('.invitable-user')
      domNode.remove()
    }
  }

  updateIcon(event) {
    let input = event.currentTarget
    let index = this.userTargets.indexOf(input)
    if (input.value == "") {
      this.showPlus(index)
    } else {
      if (this.userTargetsLength > 1) {
        this.showMinus(index)
        this.addUser(event)
      }
    }
  }

  sendInvitations(event) {
    event.preventDefault()
    this.userTargets.filter(user => user.value != "")
                    .forEach((user, i) => {
      this.sendInvite(i)
    });
  }

  sendInvite(index) {
    this.showLoader(index)

    // call api
    let form = this.userFormTargets[index]
    if (!!form) {
      Rails.fire(form, 'submit')
    }
  }

  invitationSuccess(event) {
    let index = this.userFormTargets.indexOf(event.currentTarget)
    this.showFinished(index)
    let userTarget = this.userTargets[index]
    toastr.success('Success!', `${userTarget.value} was successfully invited`)

    setTimeout(() => {
      if (this.userTargetsLength > 1) {
        userTarget.closest('.invitable-user').remove()
      } else {
        userTarget.value = ""
        this.showPlus(index)
      }
    }, 500)
  }

  invitationError(event) {
    let index = this.userFormTargets.indexOf(event.currentTarget)
    this.showError(index)
    toastr.error('Error', `${userTarget.value} couldn't be invited. Check the email and try again later.`)
  }

  hideIcons(index) {
    this.iconTypes.forEach(iconType => {
      this.iconTargets[index].querySelector(`span.${iconType}`).classList.add('hidden')
    });
  }

  showPlus(index) {
    this.hideIcons(index)
    this.iconTargets[index].querySelector('span.plus').classList.remove('hidden')
  }

  showMinus(index) {
    this.hideIcons(index)
    this.iconTargets[index].querySelector('span.minus').classList.remove('hidden')
  }

  showLoader(index) {
    this.hideIcons(index)
    this.iconTargets[index].querySelector('span.loader').classList.remove('hidden')
  }

  showFinished(index) {
    this.hideIcons(index)
    this.iconTargets[index].querySelector('span.finished').classList.remove('hidden')
  }

  showError(index) {
    this.hideIcons(index)
    this.iconTargets[index].querySelector('span.error').classList.remove('hidden')
  }

  get userTargetsLength() {
    return this.userTargets.length
  }
}
