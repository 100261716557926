import { Controller } from "stimulus"

export default class extends Controller {
  static target = [ "input" ]

  connect() {
    console.log("delete control connected")
  }

  submit(event) {
    event.preventDefault()
    // TODO: Delete for input rows is not currently working
  }
}
