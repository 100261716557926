import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "form", "addNewButton" ]

  initialize() {
    this.newUrl = this.element.dataset.integrationsNewUrlValue
  }

  new(event) {
    event.preventDefault()
    fetch(this.newUrl)
      .then(response => response.text())
      .then(html => {
        this.element.insertAdjacentHTML('beforeBegin', html)
        if (this.hasAddNewButtonTarget) {
          this.addNewButtonTarget.remove()
        }
      })
  }

  onSuccess(event) {
    let [data, status, xhr] = event.detail
    toastr.success("Integration saved successfully.")
    this.formTarget.outerHTML = xhr.response
  }

  onDelete(event) {
    let [data, status, xhr] = event.detail
    toastr.success('Integration was successfully destroyed.')
    this.element.parentElement.remove()
  }
}
