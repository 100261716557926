import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
      "form",
      "section",
      "button"
      
  ]
  connect() {
    if (!!this.notificationRecipientsAssociationInsertionNode) {
      this.boundNotificationRecipientsAssociationAfterRemove = this.notificationRecipientsAssociationCocoonAfterRemove.bind(this)
      $(this.notificationRecipientsAssociationInsertionNode).on('cocoon:after-remove', this.boundNotificationRecipientsAssociationAfterRemove)
    }
    if (!!this.associationInsertionNode) {
      this.boundAssociationAfterRemove = this.associationCocoonAfterRemove.bind(this)
      $(this.associationInsertionNode).on('cocoon:after-remove', this.boundAssociationAfterRemove)
    }

    $(document).on('check_save.enableButton', (event, callback) => {
      if (this.button) {
        this.button.disabled = false
      }
    }).bind(this)
  }
  initialize() {
    this.values = [...this.formTarget.querySelectorAll("input, select")].map((form) => {
        // return form.value
        if(form.type == 'checkbox'){
          return form.checked ? "1" : '0'
        }else{
          return form.value
        }
    }).join(',')

    this.uploadValues = [...this.formTarget.querySelectorAll(".other-form")].map((form) => {
        return form.value
    }).join(',')
  }

  setChangedTrue() {
    let forms = [...this.formTarget.querySelectorAll("input, select")];
    let formsMapped = forms.map((form) => {
      // Add new paramenter for  read check box chaNGES
      if(form.type == 'checkbox'){
        return form.checked ? "1" : '0'
      }else{
        return form.value
      }
      });


    let currentValue = formsMapped.join(',');
    this.button.disabled = this.values === currentValue
  }

  setChangedUpload() {
    let forms = [...this.formTarget.querySelectorAll(".other-form")];
    let formsMapped = forms.map((form) => {
        return form.value
      });
    let currentValue = formsMapped.join(',');
    this.button.disabled = this.uploadValues === currentValue
  }

  notificationRecipientsAssociationCocoonAfterRemove(event, insertedItem, originalEvent) {
    insertedItem[0].querySelector('input[type="email"]').value = ""
    this.button.disabled = false
  }

  associationCocoonAfterRemove(event, insertedItem, originalEvent) {
    this.setChangedTrue()
  }

  get notificationRecipientsAssociationInsertionNode() {
    return this.element.dataset.notificationRecipientsAssociationInsertionNode;
  }

  get associationInsertionNode() {
    return this.element.dataset.associationInsertionNode;
  }

  get button() {
    console.log('cvaledfldfdksfldsfkj', this.hasButtonTarget)
    console.log('cvaledfldfdksfldsfkj', this.hasButtonTarget ? this.buttonTarget : this.formTarget.querySelector('button[type="submit"]'))
    return this.hasButtonTarget ? this.buttonTarget : this.formTarget.querySelector('button[type="submit"]')
  }
}
