import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "title", "description", "text" ]

  initialize () {
      let charCountTitle = this.titleTarget.value.length;
      this.titleTarget.nextElementSibling.innerHTML = `${charCountTitle} character${(charCountTitle > 0 ? 's' :'')}`;

      let charCountDescription = this.descriptionTarget.value.length;
      this.descriptionTarget.nextElementSibling.innerHTML = `${charCountDescription} character${(charCountDescription > 0 ? 's' :'')}`;
  
  };

  setCountTitle() {
      let charCount = this.titleTarget.value.length;
      this.titleTarget.nextElementSibling.innerHTML = `${charCount} character${(charCount > 0 ? 's' :'')}`;
  }

  setCountDescription() {
      let charCount = this.descriptionTarget.value.length;
      this.descriptionTarget.nextElementSibling.innerHTML = `${charCount} character${(charCount > 0 ? 's' :'')}`;
  }
}
