import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "container" ]

  connect() {
  }

  onSuccess(event) {
    let [data, status, xhr] = event.detail
    if (this.hasContainerTarget) {
      this.containerTarget.innerHTML = xhr.response
    } else {
      this.element.outerHTML = xhr.response
    }
  }

  onError(event) {
    let [data, status, xhr] = event.detail
  }
}
