import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "list", "items", "content" ]

  initialize() {
    this.newUrlValue = this.element.dataset.pagesNewUrlValue
    this.newPageUrlValue = this.element.dataset.pagesNewPageUrlValue
  }

  connect() {
    $(document).on('pages_controller.state', (event, callback) => {
      callback(this)
    }).bind(this)

    let firstItem = this.itemsTargets[0]
    firstItem.classList.add('item-sortable--active')
  }

  refresh() {
    this.activePage.click()
  }

  getPage(event) {
    // this.loading()

    let target = event.target.closest('.item-sortable')
    let url = target.dataset.pagesControllerUrlValue

    fetch(url)
      .then(response => response.text())
      .then(html => {
        this.contentTarget.innerHTML = html
      })

    Array.from(document.querySelectorAll('.item-sortable')).forEach(el => el.classList.remove('item-sortable--active'))
    target.classList.add('item-sortable--active')
    window.scrollTo(0, 0)
  }

  loading() {
    this.contentTarget.innerHTML = "Loading..."
  }

  updateTitle(event) {
    let pageId = event.currentTarget.dataset.pagesPageId
    let pageTitle = event.currentTarget.dataset.pagesPageTitle
    if (!!pageId) {
      let pageSortItem = document.querySelector(`div#${pageId}`)
      pageSortItem.querySelector('.page-title').innerHTML = pageTitle || event.currentTarget.value
    }
  }

  get activePage() {
    return document.querySelector('.item-sortable--active[data-target~="pages.items"]')
  }
}
