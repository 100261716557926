import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "list", "items", "content" ]

  initialize() {
    this.newUrlValue = this.element.dataset.pagesNewUrlValue
    this.newPageUrlValue = this.element.dataset.pagesNewPageUrlValue
  }

  connect() {
    let pageId = this.element.dataset.pagesPageId
    // let isFirst = this.element.dataset.isFirst
    if (!!pageId) {
      let pageSortItem = document.querySelector(`div#${pageId}`)
      pageSortItem.querySelector('.page-title').innerHTML = this.element.value
    }
  }
}
