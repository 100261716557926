import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "postType", "postRedirectDetails", "postDetails", "responseRedirectDetails" ]

  connect() {
    this.update();
    // this.postRedirectDetailsTarget.classList.add('hidden')
  }

  update() {
    if (this.postTypeTarget.value == 'post') {
      this.hidePostRedirectDetails()
      this.showPostDetails()
    } else if (this.postTypeTarget.value == 'post_redirect') {
      this.hidePostDetails()
      this.showPostRedirectDetails()
    } else if (this.postTypeTarget.value == 'post_response_redirect') {
      this.hidePostRedirectDetails()
      this.showPostDetails()
    } else {
      this.hideAll()
    }
  }

  // private
  hidePostRedirectDetails() {
    this.postRedirectDetailsTarget.classList.add('hidden')
  }

  showPostRedirectDetails() {
    this.postRedirectDetailsTarget.classList.remove('hidden')
  }

  hidePostDetails() {
    this.postDetailsTargets.forEach(item => item.classList.add('hidden'))
  }

  showPostDetails() {
    this.postDetailsTargets.forEach(item => item.classList.remove('hidden'))
  }

  hideAll() {
    this.hidePostDetails()
    this.hidePostRedirectDetails()
  }
}
