import { Controller } from "stimulus"
import Sortable from "sortablejs"


export default class extends Controller {
  static targets = [ "items", "item" ]

  initialize() {
    this.sort = this.sort.bind(this)
    this.sortSuccess = this.sortSuccess.bind(this)
    this.sortable = Sortable.create(this.itemsTarget, {
      handle: '.draggable',
      onEnd: this.sort,
      draggable: ".sortable--item",
      animation: 100
    })
  }

  connect() {
    this.sortUrl = this.element.dataset.sortUrlValue
    this.sortRefreshUrl = this.element.dataset.sortRefreshUrlValue
  }

  disconnect() {
    this.sortable.destroy()
    this.sortable = undefined
  }

  sort(event) {
    Rails.ajax({
      url: this.sortUrl,
      type: 'PATCH',
      data: this.data,
      success: this.sortSuccess
    })
  }

  sortSuccess(doc, status, details) {
    if (this.sortRefreshUrl) {
      fetch(this.sortRefreshUrl)
        .then(response => response.text())
        .then(html => this.element.outerHTML = html )
    } else {
      this.itemsTarget.outerHTML = details.response
    }
  }

  // TODO: Refactor to not use jQuery
  get data() {
    if (this.itemsSortableSerialization !== '') {
      return this.itemsSortableSerialization
    }
    return this.itemsSerialization
  }

  get itemsSortableSerialization() {
    $(this.itemsTarget).sortable()
    return $(this.itemsTarget).sortable('serialize');
  }

  get itemsSerialization() {
    let model = this.itemTarget.dataset.controller
    let ids = this.itemTargets.map(item => {
      return item.dataset[`${model}IdValue`]
                 .replace(`${model}_`, `${model}[]=`)
    })
    return ids.join('&')
  }
}
