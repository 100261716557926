import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "form", "container", "newRow", "typeInput" ]

  initialize() {
    this.updateForm = this.updateForm.bind(this)
    this.indexUrl = this.element.dataset.questionsIndexUrlValue
    this.newUrl = this.element.dataset.questionsNewUrlValue
  }

  connect() {
    $(document).on('questions_controller.state', (event, callback) => {
      callback(this)
    }).bind(this)
  }

  new(event) {
    let buttonState = event.currentTarget.childNodes[1].disabled
 
    if (!buttonState ) {
      fetch(this.newUrl)
        .then(response => response.text())
        .then(html => {
          this.updateForm(html)
        })
    }
  }

  toggleEdit(event) {
    event.preventDefault()
    let questionId = event.target.closest('[data-controller="question"]').dataset.questionIdValue
    let form = this.element.querySelector(`[data-question-form~="${questionId}"]`)

    if (form.classList.contains('hidden')) {
      form.classList.remove('hidden')
    } else {
      form.classList.add('hidden')
    }
  }

  refresh() {
    fetch(this.indexUrl)
      .then(response => response.text())
      .then(html => this.refreshContainer(html))
  }

  refreshContainer(html) {
    this.element.outerHTML = html
  }

  updateForm(html) {
    this.newRowTarget.insertAdjacentHTML('beforebegin', html)
  }
}
